import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { BlogPostHeader } from "../components/blog-listing"
import PageBuilder from "../components/page-builder"

const FeaturedBlog = styled(BlogPostHeader)``

const BlogPost = ({ data }) => {
  const { craft } = data
  const { entries } = craft
  const entry = entries[0]
  const image =
    entry.images && entry.images.length > 0 && entry.images[0].lg
      ? entry.images[0].lg
      : null
  return (
    <Layout>
      <SEO title={entry.title} image={image} />
      <FeaturedBlog entry={entry} />
      {entry && entry.pageBuilder && (
        <PageBuilder
          pageBuilder={entry.pageBuilder}
          location={{ pathname: entry.uri }}
        />
      )}
    </Layout>
  )
}

export const query = graphql`
  query BlogPostQuery($id: [Craft_QueryParameter]!) {
    craft {
      entries(site: "hurleyGme", id: $id) {
        __typename
        title
        uri
        slug
        ...BlogPost
      }
    }
  }
`

export default BlogPost
